@import url("https://rsms.me/inter/inter.css");
@import "~bootstrap/dist/css/bootstrap-grid.min.css";

html {
  font-family: "Inter", sans-serif;
}

:root {
  --white: #fff;
  --gray-100: #f6faff;
  --gray-200: #eff2fb;
  --gray-300: #d9e2ec;
  --gray-400: #bcccdc;
  --gray-500: #9fb3c8;
  --gray-600: #829ab1;
  --gray-700: #486581;
  --gray-800: #334e68;
  --gray-900: #243b53;

  --green-100: #d9fdeb;
  --green-200: #b3fcdf;
  --green-300: #8cf8d6;
  --green-400: #6ff2d5;
  --green-500: #41ead4;
  --green-600: #2fc9c3;
  --green-700: #20a1a8;
  --green-800: #147687;
  --green-900: #0C5870;

  --border-radius-small: 3px;
  --form-field-width: 168.4px;
}

body {
  margin: 0;
  padding: 32px 64px;
  font-size: 16px;
  background-color: var(--gray-200);
}

@media only screen and (max-width: 580px) {
  body {
    padding: 12px;
  }
}

input:focus, button:focus {
  outline: none;
}

button {
  font-family: 'Inter', sans-serif;
  background-color: var(--green-100);
  color: var(--green-800);
  border-radius: var(--border-radius-small);
  padding: 10px;
  cursor: pointer;
  border: none;
  transition: 300ms;
}

button:disabled {
  pointer-events: none;
  opacity: 0.8;
  cursor: not-allowed;
}

button:hover {
  box-shadow: 0px 2px 6px -2px var(--green-800);
  transition: 300ms;
  transform: translateY(-3px);
}

button:active {
  transform: translateY(0px);
  box-shadow: none;
}

a {
  text-decoration: none;
  color: var(--green-900);
  position: relative;
}

.App {
  text-align: center;
}

.App-header {
  background-color: var(--white);
  height: 64px;
  padding: 0px 24px;
  border-radius: 10px;
  color: var(--gray-600);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 40px;
}

@media only screen and (max-width: 580px) {
  .logo {
    height: 25px;
  }
}

.Header-link {
  text-decoration: none;
  color: inherit;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.d-inline-flex {
  display: inline-flex;
}

.d-inline {
  display: inline;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.p-0 {
  padding: 0;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 1rem;
}
.p-4 {
  padding: 1.5rem;
}
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 1rem;
}
.m-4 {
  margin: 1.5rem;
}

.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}

/* Leaderboard */
.leaderboard {
  text-align: left;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 32px;
  color: var(--gray-900);
}

.leaderboard h3 {
  color: var(--green-800);
}

.leaderboard a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  height: 1.2rem;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 8px;
  opacity: 0;
  transition: 300ms;
  transform: scale(0,1);
  }
.leaderboard a:hover:after {
  opacity: 1;
  transform: scale(1);
}

/* Statistics */
.statistics-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;
  color: #0c5870;
}

.statistics-box h2 {
  font-weight: 400;
}

/* Trip list */
.trip-card {
  background-color: white;
  border-radius: 10px;
  min-height: 400px;
  margin-bottom: 32px;
}

.trip-icon {
  height: 16px;
}

.trip-divider {
  width: 30%;
  border: 1px dashed var(--green-500);
}

.form-layout {
  padding: 24px;
  background: var(--white);
  color: var(--gray-900);
  border-radius: 10px;
}

.form-label {
  text-align: left;
}
@media only screen and (min-width: 1240px) {
  .form-label {
    margin-left: 40px;
  }
}

input {
  border-radius: var(--border-radius-small);
  font-family: 'Inter', sans-serif;
  padding: 12px 8px;
  border: 1px solid var(--gray-400);
  font-size: 14px;
}

input:focus {
  border-color: var(--green-500);
}

.dateInput {
  height: 38px;
}

.button-cta {
  font-weight: 500;
  font-size: 20px;
  height: 40px;
  margin: auto auto 0;
  width: var(--form-field-width);
}

.button-remove {
  font-weight: 300;
  font-size: 16px;
  height: 40px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: var(--green-200);
  font-size: 12px;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}
